<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="organizations"
      hide-default-footer
      sort-by="number"
      class="elevation-1 ml-4"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-col
            cols="12"
            sm="3"
          >
          <v-select
            :items="allDistricts"
            item-text="name"
            item-value="id"
            v-model="locationId"
            dense
            outlined
            color="grey"
            hint="Filter by District"
            persistent-hint
            class="mt-8 mx-4"
            solo
          />
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
          <v-text-field
            v-model="name"
            dense
            outlined
            color="grey"
            hint="Filter by Name"
            persistent-hint
            class="mt-8 mx-4"
            solo
          />
          </v-col>
          <v-btn
            class="mt-2"
            elevation="1"
            fab
            small
            @click="loadOrganizations"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="1200px" persistent>
            <template v-slot:activator="{ on }">
              <v-btn color="green" dark class="mb-2" v-on="on" v-if="showCreate">Add Organization</v-btn>
            </template>
            <v-card>
              <validation-observer ref="form">
                <form @submit.prevent="onSubmit">
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="4" md="4">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="Organzition Name"
                      >
                      <v-text-field
                        v-model="editedOrganization.name"
                        label="Name"
                        outlined
                        name="Organzition Name"
                        :error-messages="errors"
                        color="grey"
                        dense
                        solo
                      />
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="Organization Type"
                      >
                      <v-select
                          :items="organizationtypes"
                          item-text="name"
                          item-value="id"
                          v-model="editedOrganization.typeId"
                          :error-messages="errors"
                          dense
                          label="Organization Type"
                          outlined
                          color="grey"
                          solo
                        />
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="Main Activity"
                      >
                      <v-text-field
                        v-model="editedOrganization.mainActivities"
                        label="Main Activity"
                        outlined
                        name="Main Activity"
                        :error-messages="errors"
                        color="grey"
                        dense
                        solo
                      />
                      </validation-provider>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                      >
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="District"
                      >
                      <v-select
                        :items="allDistricts"
                        item-text="name"
                        item-value="id"
                        v-model="editedOrganization.locationId"
                        dense
                        outlined
                        :error-messages="errors"
                        color="grey"
                        label="District Location"
                        solo
                      />
                      </validation-provider>
                      </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="Address"
                      >
                      <v-text-field
                        v-model="editedOrganization.address"
                        label="Address"
                        outlined
                        name="Address"
                        :error-messages="errors"
                        color="grey"
                        dense
                        solo
                      />
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="Contact Person Name"
                      >
                      <v-text-field
                        v-model="editedOrganization.contactPerson"
                        label="Contact Person Name"
                        outlined
                        name="Contact Person Name"
                        :error-messages="errors"
                        color="grey"
                        dense
                        solo
                      />
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="Position"
                      >
                      <v-text-field
                        v-model="editedOrganization.contactPosition"
                        label="Position"
                        outlined
                        name="Position"
                        :error-messages="errors"
                        color="grey"
                        dense
                        solo
                      />
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|digits:10"
                        name="Contact Phone"
                      >
                      <v-text-field
                        v-model="editedOrganization.contactPhone"
                        label="Contact Phone"
                        outlined
                        name="Contact Phone"
                        :error-messages="errors"
                        color="grey"
                        dense
                        solo
                      />
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-text-field
                        v-model="editedOrganization.contactEmail"
                        label="Contact Email"
                        outlined
                        name="Contact Email"
                        color="grey"
                        dense
                        solo
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn class="success ml-10 mb-4" text type="submit">Save</v-btn>
                <v-btn class="mb-4" color="blue darken-1" text @click="close">Cancel</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
              </form>
              </validation-observer>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.name="{ item }">
        <p @click="loadSingleOrganization(item)" class="btn blue--text">{{ item.name }}</p>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editOrganization(item)"
          v-if="showEdit"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click.stop="organizationDialog(item)"
          v-if="showDelete"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog v-model="deleteDialog" hide-overlay max-width="500px">
    <v-card tile outlined>
        <v-card-text>
          <p>Are you sure you want to delete this Organization ?</p>
          <v-btn link small color="blue" @click="deleteOrganization">Yes</v-btn>
          <v-btn link small color="blue" @click="deleteDialog = false">No</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import Organization from '@/models/Organization'
export default {
  data: () => ({
    privileges: null,
    showEdit: false,
    showDelete: false,
    showCreate: false,
    dialog: false,
    deleteDialog: false,
    organizationIndex: null,
    organizationItem: null,
    headers: [
      {
        text: 'No',
        align: 'start',
        sortable: true,
        value: 'number'
      },
      { text: 'Organization Name', value: 'name' },
      { text: 'Occupation Type', value: 'type' },
      { text: 'Main Activity', value: 'mainActivities' },
      { text: 'District', value: 'locationName' },
      { text: 'Address', value: 'address' },
      { text: 'Contact Person', value: 'contactPerson' },
      { text: 'Contact Phone Number', value: 'contactPhone' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    organizations: [],
    organizationName: null,
    editedOrganizationIndex: -1,
    editedOrganization: new Organization(),
    defaultItem: new Organization(),
    organizationtypes: [],
    statuses: ['OPEN', 'CLOSED'],
    allDistricts: [],
    emptyDistrict: { id: null, name: '' },
    name: null,
    typeId: null,
    locationId: null,
    address: null,
    contactPerson: null
  }),

  computed: {
    formTitle () {
      return this.editedOrganizationIndex === -1 ? 'New Organization' : 'Edit Organization'
    },
    formattedOrganizationDate () {
      return this.organizationDate ? this.$moment(this.organizationDate).format('DD-MMM-YYYY')
        : this.retrievedOrganizationDate ? this.$moment(this.retrievedOrganizationDate).format('DD-MMM-YYYY')
          : ''
    },
    ...mapState('store', ['snackbar', 'color', 'text', 'timeout', 'currentUser', 'currentCenter'])
  },
  mounted () {
    this.privileges = this.currentUser.privileges
    this.checkPrivileges()
  },

  watch: {
    dialog (val) {
      val || this.close()
    }
  },

  created () {
    this.loadOrganizations()
    this.loadOrganizationTypes()
    this.loadAllDistricts()
  },

  methods: {
    ...mapMutations('store', {
      setSnackbar: 'SET_SNACKBAR',
      setColor: 'SET_COLOR',
      setText: 'SET_TEXT',
      setStoreOrganization: 'SET_STORE_ORGANIZATION'
    }),
    loadOrganizations () {
      this.$delinquentService.fetchOrganizations(this.name, this.typeId, this.locationId, this.address, this.contactPerson)
        .then(response => {
          if (!response.status) {
            this.organizations = response.content
            this.organizations.forEach(element => {
              element.number = this.organizations.indexOf(element) + 1
            })
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    loadOrganizationTypes () {
      this.$delinquentService.fetchOrganizationTypes()
        .then(response => {
          if (!response.status) {
            this.organizationtypes = response.content
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },

    editOrganization (item) {
      this.editedOrganizationIndex = this.organizations.indexOf(item)
      this.organizationItem = item
      this.$delinquentService.fetchOrganizationById(item.id, null)
        .then(response => {
          if (response.id) {
            this.editedOrganization = response
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
      this.dialog = true
    },
    loadSingleOrganization (item) {
      this.editedOrganizationIndex = this.organizations.indexOf(item)
      this.organizationItem = item
      this.$delinquentService.fetchOrganizationById(item.id, 'graduands')
        .then(response => {
          if (response.id) {
            // response.graduands.content.forEach(element => {
            //   element.names = element.delinquent.firstName + ' ' + element.delinquent.lastName
            //   element.receiverNames = element.receiver.firstName + ' ' + element.receiver.lastName
            //   element.trade = element.trainingEnrolments[0].trainingClass.training.name
            //   element.delinquent.age = parseInt(this.$moment(new Date().toISOString().substr(0, 10)).format('YYYY')) - parseInt(this.$moment(element.delinquent.dateOfBirth).format('YYYY'))
            // })
            this.setStoreOrganization(response)
            this.$router.push('/organization/single-organization')
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    organizationDialog (item) {
      this.organizationIndex = this.organizations.indexOf(item)
      this.organizationItem = item
      this.deleteDialog = true
      this.alertDialog = false
      this.alert = false
    },

    deleteOrganization () {
      this.deleteDialog = false
      this.$delinquentService.deleteOrganization(this.organizationItem.id)
        .then(response => {
          if (response.status === 'success') {
            this.loadOrganizations()
            // this.organizations.splice(this.organizationIndex, 1)
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedOrganization = Object.assign({}, this.defaultItem)
        this.editedOrganizationIndex = -1
      }, 300)
    },
    onSubmit () {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return
        }
        if (this.editedOrganizationIndex > -1) {
          this.updateOrganization()
        } else this.createOrganization()
        this.$nextTick(() => {
          this.$refs.form.reset()
        })
      })
    },
    updateOrganization () {
      const deletedOrganizationArray = this.organizations.splice(this.editedOrganizationIndex, 1)
      delete this.editedOrganization.type
      delete this.editedOrganization.locationName
      delete this.editedOrganization.locationType
      this.$delinquentService.saveOrganization(this.editedOrganization)
        .then(response => {
          if (response.id) {
            this.loadOrganizations()
            // this.organizations.splice(this.editedOrganizationIndex, 1, response)
            // this.organizations[this.editedOrganizationIndex].number = this.organizationItem.number
            this.close()
          } else {
            this.organizations.splice(this.editedOrganizationIndex, 0, deletedOrganizationArray[0])
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    createOrganization () {
      this.$delinquentService.saveOrganization(this.editedOrganization)
        .then(response => {
          if (!response.status) {
            this.loadOrganizations()
            // this.organizations.unshift(response)
            this.close()
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    loadAllDistricts () {
      this.$locationService.fetchAllDistricts()
        .then(response => {
          if (!response.status) {
            this.allDistricts = response
            this.allDistricts.unshift(this.emptyDistrict)
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    saveOrganizationDate (organizationDate) {
      this.$refs.organizationDateMenu.save(organizationDate)
    },
    checkPrivileges () {
      this.privileges.indexOf('CREATE_GRADUATIONS') > -1 || this.privileges.indexOf('ALL_AUTHORITIES') > -1 ? this.showCreate = true : this.showCreate = false
      this.privileges.indexOf('UPDATE_GRADUATIONS') > -1 || this.privileges.indexOf('ALL_AUTHORITIES') > -1 ? this.showEdit = true : this.showEdit = false
      this.privileges.indexOf('DELETE_GRADUATIONS') > -1 || this.privileges.indexOf('ALL_AUTHORITIES') > -1 ? this.showDelete = true : this.showDelete = false
    }
  }
}
</script>
<style scoped>
.btn {
  cursor: pointer;
}
</style>
